export const palette = {
  neutral: {
    10: "hsl(210, 5%, 98%)",
    20: "hsl(210, 5%, 94%)",
    40: "hsl(210, 5%, 87%)",
    60: "hsl(210, 10%, 58%)",
    80: "hsl(210, 10%, 40%)",
    90: "hsl(210, 25%, 25%)",
    100: "sl(210, 50%, 10%)",
  },
  red: {
    10: "hsl(0, 75%, 95%)",
    20: "hsl(0, 75%, 85%)",
    40: "hsl(0, 75%, 75%)",
    60: "hsl(0, 50%, 50%)",
    80: "hsl(0, 95%, 30%)",
    90: "hsl(0, 100%, 20%)",
    100: "hsl(0, 100%, 15%)",
  },
  orange: {
    10: "hsl(30, 75%, 95%)",
    20: "hsl(30, 75%, 85%)",
    40: "hsl(30, 75%, 75%)",
    60: "hsl(30, 50%, 50%)",
    80: "hsl(30, 95%, 30%)",
    90: "hsl(30, 100%, 20%)",
    100: "hsl(30, 100%, 15%)",
  },
  yellow: {
    10: "hsl(60, 75%, 95%)",
    20: "hsl(60, 75%, 85%)",
    40: "hsl(60, 75%, 75%)",
    60: "hsl(60, 50%, 50%)",
    80: "hsl(60, 95%, 30%)",
    90: "hsl(60, 100%, 20%)",
    100: "hsl(60, 100%, 15%)",
  },
  green: {
    10: "hsl(130, 60%, 95%)",
    20: "hsl(130, 60%, 90%)",
    40: "hsl(130, 44%, 63%)",
    60: "hsl(130, 43%, 46%)",
    80: "hsl(130, 33%, 37%)",
    90: "hsl(130, 27%, 29%)",
    100: "hsl(130, 22%, 23%)",
  },
  teal: {
    10: "hsl(190, 75%, 95%)",
    20: "hsl(190, 75%, 85%)",
    40: "hsl(190, 70%, 70%)",
    60: "hsl(190, 50%, 50%)",
    80: "hsl(190, 95%, 30%)",
    90: "hsl(190, 100%, 20%)",
    100: "hsl(190, 100%, 15%)",
  },
  blue: {
    10: "hsl(220, 95%, 95%)",
    20: "hsl(220, 85%, 85%)",
    40: "hsl(220, 70%, 70%)",
    60: "hsl(220, 50%, 50%)",
    80: "hsl(220, 95%, 30%)",
    90: "hsl(220, 100%, 20%)",
    100: "hsl(220, 100%, 15%)",
  },
  purple: {
    10: "hsl(300, 95%, 95%)",
    20: "hsl(300, 85%, 85%)",
    40: "hsl(300, 70%, 70%)",
    60: "hsl(300, 50%, 50%)",
    80: "hsl(300, 95%, 30%)",
    90: "hsl(300, 100%, 20%)",
    100: "hsl(300, 100%, 15%)",
  },

  pink: {
    10: "hsl(340, 95%, 95%)",
    20: "hsl(340, 90%, 85%)",
    40: "hsl(340, 70%, 70%)",
    60: "hsl(340, 50%, 50%)",
    80: "hsl(340, 95%, 30%)",
    90: "hsl(340, 100%, 20%)",
    100: "hsl(340, 100%, 15%)",
  },
  white: "hsl(0, 0%, 100%)",
  black: "hsl(0, 0%, 0%)",
  transparent: "transparent",
};
