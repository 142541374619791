import { PuffLoader } from "react-spinners";

export const enLang = {
  en: {
    errorLabelText: "Error",
    timeoutHeaderText: "Time out",
    timeoutMessageText:
      "Face didn't fit inside oval in time limit. Try again and completely fill the oval with face in it.",
    faceDistanceHeaderText: "Forward movement detected",
    faceDistanceMessageText: "Avoid moving closer when connecting.",
    multipleFacesHeaderText: "Multiple faces detected",
    multipleFacesMessageText:
      "Ensure only one face is present in front of the camera when connecting.",
    clientHeaderText: "Client error",
    clientMessageText: "Check failed due to client issue",
    serverHeaderText: "Server issue",
    serverMessageText: "Cannot complete check due to server issue",
    landscapeHeaderText: "Landscape orientation not supported",
    landscapeMessageText:
      "Rotate your device to portrait (vertical) orientation.",
    portraitMessageText:
      "Ensure your device remains in portrait (vertical) orientation for the check’s duration.",
    tryAgainText: "Try again",
    cameraMinSpecificationsHeadingText:
      "Camera does not meet minimum specifications",
    cameraMinSpecificationsMessageText:
      "Camera must support at least 320*240 resolution and 15 frames per second.",
    cameraNotFoundHeadingText: "Camera is not accessible.",
    cameraNotFoundMessageText:
      "Check that a camera is connected and there is not another application using the camera. You may have to go into settings to grant camera permissions and close out all instances of your browser and retry.",
    a11yVideoLabelText: "Webcam for liveness check",
    cancelLivenessCheckText: "Cancel Liveness check",
    goodFitCaptionText: "Good fit",
    goodFitAltText:
      "Ilustration of a person's face, perfectly fitting inside of an oval.",
    hintCenterFaceText: "",
    hintCenterFaceInstructionText:
      "Instruction: Before starting the check, make sure your camera is at the center top of your screen and center your face to the camera. When the check starts an oval will show up in the center. You will be prompted to move forward into the oval and then prompted to hold still. After holding still for a few seconds, you should hear check complete.",
    hintFaceOffCenterText:
      "Face is not in the oval, center your face to the camera.",
    hintMoveFaceFrontOfCameraText: "Move face in front of camera",
    hintTooManyFacesText: "Ensure only one face is in front of camera",
    hintFaceDetectedText: "Face detected",
    hintCanNotIdentifyText: "Move face in front of camera",
    hintTooCloseText: "Move back",
    hintTooFarText: "Move closer",
    hintConnectingText: "Connecting...",
    hintVerifyingText: "Verifying...",
    hintCheckCompleteText: "Check complete",
    hintIlluminationTooBrightText: "Move to dimmer area",
    hintIlluminationTooDarkText: "Move to brighter area",
    hintIlluminationNormalText: "Lighting conditions normal",
    hintHoldFaceForFreshnessText: "Hold still",
    hintMatchIndicatorText: "50% completed. Keep moving closer.",
    photosensitivityWarningBodyText:
      "This check flashes different colors. Use caution if you are photosensitive.",
    photosensitivityWarningHeadingText: "Photosensitivity warning",
    photosensitivityWarningInfoText:
      "Some people may experience epileptic seizures when exposed to colored lights. Use caution if you, or anyone in your family, have an epileptic condition.",
    photosensitivityWarningLabelText: "More information about photosensitivity",
    photosensitivyWarningBodyText:
      "This check flashes different colors. Use caution if you are photosensitive.",
    photosensitivyWarningHeadingText: "Photosensitivity warning",
    photosensitivyWarningInfoText:
      "Some people may experience epileptic seizures when exposed to colored lights. Use caution if you, or anyone in your family, have an epileptic condition.",
    photosensitivyWarningLabelText: "More information about photosensitivity",
    retryCameraPermissionsText: "Retry",
    recordingIndicatorText: "Rec",
    startScreenBeginCheckText: <><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7071 4.29289C12.3166 3.90237 11.6834 3.90237 11.2929 4.29289C10.9024 4.68342 10.9024 5.31658 11.2929 5.70711L16.5858 11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H16.5858L11.2929 18.2929C10.9024 18.6834 10.9024 19.3166 11.2929 19.7071C11.6834 20.0976 12.3166 20.0976 12.7071 19.7071L19.7064 12.7078C19.7088 12.7054 19.7112 12.703 19.7136 12.7005C19.8901 12.5208 19.9992 12.2746 20 12.003C20 12.002 20 12.001 20 12C20 11.999 20 11.998 20 11.997C19.9996 11.8625 19.9727 11.7343 19.9241 11.6172C19.8764 11.502 19.8063 11.3938 19.7136 11.2995C19.7112 11.297 19.7089 11.2947 19.7065 11.2923M12.7071 4.29289L19.7065 11.2923L12.7071 4.29289Z" fill="#FFFDFA"/>
    </svg>Start video check</>,
    tooFarCaptionText: "Too far",
    tooFarAltText:
      "Illustration of a person's face inside of an oval; there is a gap between the perimeter of the face and the boundaries of the oval.",
    waitingCameraPermissionText: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <PuffLoader color="gray" />
        <p style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
          Waiting for you to allow camera permission.
        </p>
      </div>
    ),
  },
};

export const esLang = {
  es: {
    errorLabelText: "Error",
    timeoutHeaderText: "Se acabó el tiempo",
    timeoutMessageText:
      "La cara no estaba dentro del óvalo en el límite de tiempo. Inténtalo de nuevo y llena completamente el óvalo con la cara.",
    faceDistanceHeaderText: "Movimiento hacia adelante detectado",
    faceDistanceMessageText: "Evite acercarse al conectarse.",
    multipleFacesHeaderText: "Múltiples caras detectadas",
    multipleFacesMessageText:
      "Asegúrese de que solo haya una cara frente a la cámara al realizar la conexión.",
    clientHeaderText: "Error del cliente",
    clientMessageText: "La verificación falló debido a un problema del cliente",
    serverHeaderText: "Problema con el servidor",
    serverMessageText:
      "No se puede completar la verificación debido a un problema con el servidor",
    landscapeHeaderText: "No se admite la orientación horizontal",
    landscapeMessageText: "Gire su dispositivo a orientación vertical.",
    portraitMessageText:
      "Asegúrese de que su dispositivo permanezca en orientación vertical mientras dure la verificación.",
    tryAgainText: "Intentar otra vez",
    cameraMinSpecificationsHeadingText:
      "La cámara no cumple con las especificaciones mínimas",
    cameraMinSpecificationsMessageText:
      "La cámara debe admitir al menos una resolución de 320*240 y 15 fotogramas por segundo.",
    cameraNotFoundHeadingText: "No se puede acceder a la cámara.",
    cameraNotFoundMessageText:
      "Compruebe que haya una cámara conectada y que no haya otra aplicación utilizando la cámara. Es posible que tengas que ingresar a la configuración para otorgar permisos a la cámara, cerrar todas las instancias de tu navegador y volver a intentarlo.",
    a11yVideoLabelText: "Cámara web para comprobar la fé de vida",
    cancelLivenessCheckText: "Cancelar validación",
    goodFitCaptionText: "Buen ajuste",
    goodFitAltText:
      "Ilustración del rostro de una persona, encajando perfectamente dentro de un óvalo.",
    hintCenterFaceText: "",
    hintCenterFaceInstructionText:
      "Instrucciones: Antes de comenzar la verificación, asegúrese de que su cámara esté en la parte superior central de la pantalla y centre su rostro en la cámara. Cuando comience la verificación, aparecerá un óvalo en el centro. Se le pedirá que avance hacia el óvalo y luego que se quede quieto. Después de permanecer quieto durante unos segundos, debería escuchar la verificación completa.",
    hintFaceOffCenterText:
      "La cara no está en el óvalo, centra tu cara hacia la cámara.",
    hintMoveFaceFrontOfCameraText: "Mover la cara frente a la cámara",
    hintTooManyFacesText:
      "Asegúrese de que solo haya una cara frente a la cámara",
    hintFaceDetectedText: "Cara detectada",
    hintCanNotIdentifyText: "Mover la cara frente a la cámara",
    hintTooCloseText: "Retroceder",
    hintTooFarText: "Muévete mas cerca",
    hintConnectingText: "Conectando...",
    hintVerifyingText: "Verificando...",
    hintCheckCompleteText: "Comprobación completa",
    hintIlluminationTooBrightText: "Muévete a un área con menos iluminación",
    hintIlluminationTooDarkText: "Muévete a un área mejor iluminada",
    hintIlluminationNormalText: "Condiciones de iluminación normales",
    hintHoldFaceForFreshnessText: "Quédate quieto",
    hintMatchIndicatorText: "50% completado, Sigue acercándote",
    photosensitivityWarningBodyText:
      "Esta validación parpadea en diferentes colores. Tenga cuidado si es fotosensible.",
    photosensitivityWarningHeadingText: "Advertencia de fotosensibilidad",
    photosensitivityWarningInfoText:
      "Algunas personas pueden experimentar ataques epilépticos cuando se exponen a luces de colores. Tenga cuidado si usted o alguien de su familia padece una enfermedad epiléptica.",
    photosensitivityWarningLabelText:
      "Más información sobre la fotosensibilidad",
    photosensitivyWarningBodyText:
      "Esta validación parpadea en diferentes colores. Tenga cuidado si es fotosensible.",
    photosensitivyWarningHeadingText: "Advertencia de fotosensibilidad",
    photosensitivyWarningInfoText:
      "Algunas personas pueden experimentar ataques epilépticos cuando se exponen a luces de colores. Tenga cuidado si usted o alguien de su familia padece una enfermedad epiléptica.",
    photosensitivyWarningLabelText: "Más información sobre la fotosensibilidad",
    retryCameraPermissionsText: "Reintentar",
    recordingIndicatorText: "Grabando",
    startScreenBeginCheckText: <><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7071 4.29289C12.3166 3.90237 11.6834 3.90237 11.2929 4.29289C10.9024 4.68342 10.9024 5.31658 11.2929 5.70711L16.5858 11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H16.5858L11.2929 18.2929C10.9024 18.6834 10.9024 19.3166 11.2929 19.7071C11.6834 20.0976 12.3166 20.0976 12.7071 19.7071L19.7064 12.7078C19.7088 12.7054 19.7112 12.703 19.7136 12.7005C19.8901 12.5208 19.9992 12.2746 20 12.003C20 12.002 20 12.001 20 12C20 11.999 20 11.998 20 11.997C19.9996 11.8625 19.9727 11.7343 19.9241 11.6172C19.8764 11.502 19.8063 11.3938 19.7136 11.2995C19.7112 11.297 19.7089 11.2947 19.7065 11.2923M12.7071 4.29289L19.7065 11.2923L12.7071 4.29289Z" fill="#FFFDFA"/>
    </svg>Iniciar validación</>,
    tooFarCaptionText: "Demasiado lejos",
    tooFarAltText:
      "Ilustración del rostro de una persona dentro de un óvalo, hay un espacio entre el perímetro de la cara y los límites del óvalo.",
    waitingCameraPermissionText: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <PuffLoader color="gray" />
        <p style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
          Esperando que permitas el acceso a la cámara.
        </p>
      </div>
    ),
  },
};
