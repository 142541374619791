import React, { useEffect, useState } from "react";

const Result = (props) => {
  const { result, primaryColor, lang } = props;

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: result ? "green" : "red",
          height: "100vh",
          width: "100vw",
          textAlign: "center",
        }}
      >
        {result ? (
          <>
            {lang === "ES" ? (
              <h1 style={{ color: "white" }}>¡Validación exitosa!</h1>
            ) : (
              <h1 style={{ color: "white" }}>Successful validation!</h1>
            )}
          </>
        ) : (
          <>
            {lang === "ES" ? (
              <h1 style={{ color: "white" }}>
                La validación no pudo ser completada correctamente, por favor
                reintente.
              </h1>
            ) : (
              <h1 style={{ color: "white" }}>
                Validation could not be completed correctly, please retry.
              </h1>
            )}
            <button
              style={{
                paddingLeft: "1rem",
                paddingRight: "1rem",
                border: "none",
                boxShadow: "0px 0px 3px black",
                borderRadius: "5px",
                cursor: "pointer",
                backgroundColor: primaryColor === "red" ? "gray" : primaryColor,
                color: "white",
                marginTop: "1.5rem",
                padding: "0.5rem",
              }}
              onClick={() => window.location.replace(window.location.href)}
            >
              {lang === "ES" ? "Reintentar" : "Retry"}
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default Result;
